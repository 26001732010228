import { Heading, Text, Box, Image } from "@chakra-ui/react";
import * as React from "react";
import NstLogo from "../images/NST.png";

const Sponsors = () => {
  return (
    <Box as="section">
      <Box mb="60px">
        <Text fontWeight="Bold" mb="16px">
          Sponsored by
          <Text opacity="0.3">
            <i>Wir suchen Partner! Hier könnte Ihr Logo stehen!</i>
          </Text>
        </Text>
        <Image src={NstLogo} width="300px" alt="New Skribble Times" />
      </Box>
    </Box>
  );
};

export default Sponsors;
