import * as React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import logo from "../images/canvasleague.svg";

const Header = () => {
  return (
    <Box as="header" mt="64px" w="100%">
      <Box className="separator">
        <Image mx="64px" src={logo} width="130px" alt="Canvas League" />
      </Box>
    </Box>
  );
};

export default Header;
