import * as React from "react";
import { Box, Flex, VStack } from "@chakra-ui/react";
import Header from "../Components/Header";
import Intro from "../Components/Intro";
import MiniGame from "../Components/MiniGame";
import Trophies from "../Components/Trophies";
import Seasons from "../Components/Seasons";
import Eternal from "../Components/Eternal";
import Sponsors from "../Components/Sponsors";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>Canvas League - Season 5</title>
      </Helmet>
      <Flex justifyContent="center">
        <Box width="100%" maxWidth="1240px">
          <VStack spacing="120px" align="stretch">
            <Header />
            <Intro />
            <MiniGame />
            <Trophies />
            <Seasons />
            <Eternal />
            <Sponsors />
          </VStack>
        </Box>
      </Flex>
    </main>
  );
};

export default IndexPage;
