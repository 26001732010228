import {
  Heading,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import * as React from "react";

// https://csvjson.com/csv2json

const CSVData = [
  {
    Name: "Max 🥇🥇🥈",
    EternalPoints: 8,
    Gold: 2,
    Silber: 1,
    Bronze: "",
    GoldenCupcake: "",
    Season01: 1,
    PunkteS01: 167,
    SpieleS01: 13,
    Season02: 1,
    PunkteS02: 159,
    SpieleS02: 12,
    Season03: 2,
    PunkteS03: 118,
    PunktePlayoffsS03: 36,
    SpieleS03: 10,
    Season04: 5,
    PunkteS04: 105,
  },
  {
    Name: "Phil 🥈🥈🥇🥉",
    EternalPoints: 8,
    Gold: 1,
    Silber: 2,
    Bronze: 1,
    GoldenCupcake: "",
    Season01: 2,
    PunkteS01: 157,
    SpieleS01: 13,
    Season02: 2,
    PunkteS02: 138,
    SpieleS02: 12,
    Season03: 1,
    PunkteS03: 132,
    PunktePlayoffsS03: 39,
    SpieleS03: 11,
    Season04: 3,
    PunkteS04: 126,
  },
  {
    Name: "Yann 🥉🥉🧁🥇",
    EternalPoints: "5,5",
    Gold: 1,
    Silber: "",
    Bronze: 2,
    GoldenCupcake: 1,
    Season01: 3,
    PunkteS01: 127,
    SpieleS01: 13,
    Season02: 3,
    PunkteS02: 117,
    SpieleS02: 12,
    Season03: 4,
    PunkteS03: 103,
    PunktePlayoffsS03: 30,
    SpieleS03: 11,
    Season04: 1,
    PunkteS04: 148,
  },
  {
    Name: "Julia 🧁🥇",
    EternalPoints: "3,5",
    Gold: 1,
    Silber: "",
    Bronze: "",
    GoldenCupcake: 1,
    Season01: 4,
    PunkteS01: 113,
    SpieleS01: 13,
    Season02: 8,
    PunkteS02: 63,
    SpieleS02: 11,
    Season03: 9,
    PunkteS03: 46,
    PunktePlayoffsS03: "",
    SpieleS03: 8,
    Season04: 1,
    PunkteS04: 148,
  },
  {
    Name: "Ali 🥉🥈",
    EternalPoints: 3,
    Gold: "",
    Silber: 1,
    Bronze: 1,
    GoldenCupcake: "",
    Season01: 8,
    PunkteS01: 27,
    SpieleS01: 3,
    Season02: 7,
    PunkteS02: 75,
    SpieleS02: 8,
    Season03: 3,
    PunkteS03: 118,
    PunktePlayoffsS03: 31,
    SpieleS03: 10,
    Season04: 2,
    PunkteS04: 130,
  },
  {
    Name: "Veronika 🥈",
    EternalPoints: 2,
    Gold: "",
    Silber: 1,
    Bronze: "",
    GoldenCupcake: "",
    Season01: "",
    PunkteS01: "",
    SpieleS01: "",
    Season02: 11,
    PunkteS02: 14,
    SpieleS02: 3,
    Season03: 10,
    PunkteS03: 39,
    PunktePlayoffsS03: "",
    SpieleS03: 8,
    Season04: 2,
    PunkteS04: 130,
  },
  {
    Name: "Nina 🥉",
    EternalPoints: 1,
    Gold: "",
    Silber: "",
    Bronze: 1,
    GoldenCupcake: "",
    Season01: "",
    PunkteS01: "",
    SpieleS01: "",
    Season02: 13,
    PunkteS02: 9,
    SpieleS02: 3,
    Season03: 11,
    PunkteS03: 28,
    PunktePlayoffsS03: "",
    SpieleS03: 9,
    Season04: 3,
    PunkteS04: 126,
  },
  {
    Name: "Domi 🧁",
    EternalPoints: "0,5",
    Gold: "",
    Silber: "",
    Bronze: "",
    GoldenCupcake: 1,
    Season01: 7,
    PunkteS01: 51,
    SpieleS01: 7,
    Season02: 5,
    PunkteS02: 98,
    SpieleS02: 12,
    Season03: 5,
    PunkteS03: 78,
    PunktePlayoffsS03: 26,
    SpieleS03: 9,
    Season04: 4,
    PunkteS04: 113,
  },
  {
    Name: "Sascha 🧁",
    EternalPoints: "0,5",
    Gold: "",
    Silber: "",
    Bronze: "",
    GoldenCupcake: 1,
    Season01: 5,
    PunkteS01: 100,
    SpieleS01: 13,
    Season02: 4,
    PunkteS02: 109,
    SpieleS02: 12,
    Season03: 7,
    PunkteS03: 63,
    PunktePlayoffsS03: "",
    SpieleS03: 10,
    Season04: 7,
    PunkteS04: 62,
  },
  {
    Name: "Woke 🧁",
    EternalPoints: "0,5",
    Gold: "",
    Silber: "",
    Bronze: "",
    GoldenCupcake: 1,
    Season01: "",
    PunkteS01: "",
    SpieleS01: "",
    Season02: 9,
    PunkteS02: 45,
    SpieleS02: 5,
    Season03: "",
    PunkteS03: "",
    PunktePlayoffsS03: "",
    SpieleS03: "",
    Season04: 4,
    PunkteS04: 113,
  },
  {
    Name: "Chris",
    EternalPoints: 0,
    Gold: "",
    Silber: "",
    Bronze: "",
    GoldenCupcake: "",
    Season01: "",
    PunkteS01: "",
    SpieleS01: "",
    Season02: 15,
    PunkteS02: 4,
    SpieleS02: 1,
    Season03: "",
    PunkteS03: "",
    PunktePlayoffsS03: "",
    SpieleS03: "",
    Season04: "",
    PunkteS04: "",
  },
  {
    Name: "Cydrux",
    EternalPoints: 0,
    Gold: "",
    Silber: "",
    Bronze: "",
    GoldenCupcake: "",
    Season01: "",
    PunkteS01: "",
    SpieleS01: "",
    Season02: 16,
    PunkteS02: 4,
    SpieleS02: 1,
    Season03: "",
    PunkteS03: "",
    PunktePlayoffsS03: "",
    SpieleS03: "",
    Season04: "",
    PunkteS04: "",
  },
  {
    Name: "Han",
    EternalPoints: 0,
    Gold: "",
    Silber: "",
    Bronze: "",
    GoldenCupcake: "",
    Season01: "",
    PunkteS01: "",
    SpieleS01: "",
    Season02: 17,
    PunkteS02: 4,
    SpieleS02: 1,
    Season03: 13,
    PunkteS03: 2,
    PunktePlayoffsS03: "",
    SpieleS03: 1,
    Season04: 5,
    PunkteS04: 105,
  },
  {
    Name: "Ina",
    EternalPoints: 0,
    Gold: "",
    Silber: "",
    Bronze: "",
    GoldenCupcake: "",
    Season01: "",
    PunkteS01: "",
    SpieleS01: "",
    Season02: 19,
    PunkteS02: 1,
    SpieleS02: 1,
    Season03: "",
    PunkteS03: "",
    PunktePlayoffsS03: "",
    SpieleS03: "",
    Season04: "",
    PunkteS04: "",
  },
  {
    Name: "JanM",
    EternalPoints: 0,
    Gold: "",
    Silber: "",
    Bronze: "",
    GoldenCupcake: "",
    Season01: 10,
    PunkteS01: 24,
    SpieleS01: 3,
    Season02: "",
    PunkteS02: "",
    SpieleS02: "",
    Season03: "",
    PunkteS03: "",
    PunktePlayoffsS03: "",
    SpieleS03: "",
    Season04: "",
    PunkteS04: "",
  },
  {
    Name: "Laura",
    EternalPoints: 0,
    Gold: "",
    Silber: "",
    Bronze: "",
    GoldenCupcake: "",
    Season01: "",
    PunkteS01: "",
    SpieleS01: "",
    Season02: "",
    PunkteS02: "",
    SpieleS02: "",
    Season03: 14,
    PunkteS03: 1,
    PunktePlayoffsS03: "",
    SpieleS03: 1,
    Season04: "",
    PunkteS04: "",
  },
  {
    Name: "Lauritz",
    EternalPoints: 0,
    Gold: "",
    Silber: "",
    Bronze: "",
    GoldenCupcake: "",
    Season01: "",
    PunkteS01: "",
    SpieleS01: "",
    Season02: 18,
    PunkteS02: 3,
    SpieleS02: 1,
    Season03: "",
    PunkteS03: "",
    PunktePlayoffsS03: "",
    SpieleS03: "",
    Season04: "",
    PunkteS04: "",
  },
  {
    Name: "Mel",
    EternalPoints: 0,
    Gold: "",
    Silber: "",
    Bronze: "",
    GoldenCupcake: "",
    Season01: 13,
    PunkteS01: 0,
    SpieleS01: 1,
    Season02: 14,
    PunkteS02: 7,
    SpieleS02: 2,
    Season03: "",
    PunkteS03: "",
    PunktePlayoffsS03: "",
    SpieleS03: "",
    Season04: "",
    PunkteS04: "",
  },
  {
    Name: "Nigel",
    EternalPoints: 0,
    Gold: "",
    Silber: "",
    Bronze: "",
    GoldenCupcake: "",
    Season01: 6,
    PunkteS01: 53,
    SpieleS01: 7,
    Season02: 12,
    PunkteS02: 12,
    SpieleS02: 2,
    Season03: 12,
    PunkteS03: 19,
    PunktePlayoffsS03: "",
    SpieleS03: 4,
    Season04: "",
    PunkteS04: "",
  },
  {
    Name: "Niklas",
    EternalPoints: 0,
    Gold: "",
    Silber: "",
    Bronze: "",
    GoldenCupcake: "",
    Season01: 9,
    PunkteS01: 26,
    SpieleS01: 3,
    Season02: "",
    PunkteS02: "",
    SpieleS02: "",
    Season03: "",
    PunkteS03: "",
    PunktePlayoffsS03: "",
    SpieleS03: "",
    Season04: "",
    PunkteS04: "",
  },
  {
    Name: "Niko",
    EternalPoints: 0,
    Gold: "",
    Silber: "",
    Bronze: "",
    GoldenCupcake: "",
    Season01: "",
    PunkteS01: "",
    SpieleS01: "",
    Season02: 10,
    PunkteS02: 40,
    SpieleS02: 9,
    Season03: 8,
    PunkteS03: 47,
    PunktePlayoffsS03: "",
    SpieleS03: 11,
    Season04: 6,
    PunkteS04: 84,
  },
  {
    Name: "Nini",
    EternalPoints: 0,
    Gold: "",
    Silber: "",
    Bronze: "",
    GoldenCupcake: "",
    Season01: 12,
    PunkteS01: 9,
    SpieleS01: 2,
    Season02: 6,
    PunkteS02: 79,
    SpieleS02: 12,
    Season03: 6,
    PunkteS03: 65,
    PunktePlayoffsS03: "",
    SpieleS03: 9,
    Season04: 6,
    PunkteS04: 84,
  },
  {
    Name: "Rike",
    EternalPoints: 0,
    Gold: "",
    Silber: "",
    Bronze: "",
    GoldenCupcake: "",
    Season01: "",
    PunkteS01: "",
    SpieleS01: "",
    Season02: "",
    PunkteS02: "",
    SpieleS02: "",
    Season03: "",
    PunkteS03: "",
    PunktePlayoffsS03: "",
    SpieleS03: "",
    Season04: 7,
    PunkteS04: 62,
  },
  {
    Name: "Skinny",
    EternalPoints: 0,
    Gold: "",
    Silber: "",
    Bronze: "",
    GoldenCupcake: "",
    Season01: 11,
    PunkteS01: 13,
    SpieleS01: 2,
    Season02: "",
    PunkteS02: "",
    SpieleS02: "",
    Season03: "",
    PunkteS03: "",
    PunktePlayoffsS03: "",
    SpieleS03: "",
    Season04: "",
    PunkteS04: "",
  },
];
const Eternal = () => {
  // Sort

  return (
    <Box as="section">
      <Box>
        <Heading className="gradientText" as="h2" size="3xl" maxWidth="700px">
          Weltrangliste
        </Heading>
      </Box>
      <Box>
        <Table variant="simple" size="lg">
          <Thead>
            <Tr>
              <Th>Platzierung</Th>
              <Th>Spieler</Th>
              <Th isNumeric>Gold</Th>
              <Th isNumeric>Silber</Th>
              <Th isNumeric>Bronze</Th>
              <Th isNumeric>Golden Cupcake</Th>
              <Th isNumeric>Eternal Points</Th>
            </Tr>
          </Thead>
          <Tbody>
            {CSVData.map((player, index) => {
              return (
                <Tr
                  key={index}
                  _hover={{ background: "rgba(255,255,255,0.1)" }}
                >
                  <Td>{index + 1}</Td>
                  <Td fontWeight="bold">{player.Name}</Td>
                  <Td isNumeric>{player.Gold === "" ? "0" : player.Gold}</Td>
                  <Td isNumeric>
                    {player.Silber === "" ? "0" : player.Silber}
                  </Td>
                  <Td isNumeric>
                    {player.Bronze === "" ? "0" : player.Bronze}
                  </Td>
                  <Td isNumeric>
                    {player.GoldenCupcake === "" ? "0" : player.GoldenCupcake}
                  </Td>
                  <Td fontWeight="bold" isNumeric>
                    {player.EternalPoints}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default Eternal;
