import { Text, Heading, Box, Button, Flex } from "@chakra-ui/react";
import { useSpring, animated, a, useTrail } from "react-spring";
import { useState, useRef, Suspense, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import {
  Environment,
  OrbitControls,
  Stage,
  PerspectiveCamera,
} from "@react-three/drei";
import * as React from "react";
import "../styles/five.css";
import TrophyGold from "./TrophyGold";

const calc = (x, y, rect) => [
  -(y - rect.top - rect.height / 2) / 8,
  (x - rect.left - rect.width / 2) / 8,
  1.4,
];
const trans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const Trail = ({ open, children }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 1, tension: 160, friction: 26 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    delay: 300,
    height: open ? 110 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });
  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} className="trailsText" style={style}>
          <a.div style={{ height }}>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  );
};

const Intro = () => {
  const [open, setOpen] = useState(true);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  return (
    <>
      <Box className="ccard-main">
        <div
          className="ccard"
          style={{ transform: `perspective(600px) rotateX(${offset / 10}deg)` }}
        >
          5
        </div>
      </Box>

      <Box pointerEvents="none" as="section" position="relative">
        <Box>
          {/* <Heading
          minH="50vh"
          as="h1"
          fontSize="160px"
          lineHeight="160px"
          letterSpacing="-3.5px"
          w="80%"
          // className="gradientText"
          pointerEvents="auto"
          onClick={() => setOpen(!open)}
        > */}
          <Box display="flex" alignItems="center" h="100%">
            <Trail open={open}>
              <span>Be part</span>
              <span>of the</span>
              <span>next</span>
              <span>chapter!</span>
            </Trail>
          </Box>
          {/* </Heading> */}
          <Heading mt="32px" size="lg">
            #cl #season05
          </Heading>
          <Text mt="8px" fontWeight="bold">
            Es wird wieder Geschichte gezeichnet!
          </Text>
          <Box mt="32px">
            <a href="https://nuudel.digitalcourage.de/canvas-league-season-5">
              <Button
                size="lg"
                pointerEvents="auto"
                className="gradient"
                borderRadius="full"
                fontWeight="bold"
                transition="all"
                transitionDuration="2s"
                background="linear-gradient(98.44deg, #f400f9 -2.9%, #54d5b0 80.9%)"
                _hover={{
                  background:
                    "linear-gradient(60deg, rgba(201, 1, 205, 1) -2.9%, rgba(69, 176, 146, 1) 80.9%)",
                }}
              >
                Ich will dabei sein!
              </Button>
            </a>
          </Box>
        </Box>
        <Box
          zIndex="30"
          position="absolute"
          h="100vh"
          top="0%"
          left="60%"
          right="0"
        >
          <Canvas style={{ pointerEvents: "auto" }}>
            {/* <PerspectiveCamera makeDefault /> */}
            <Suspense fallback={null}>
              <Stage environment="night">
                <TrophyGold />
              </Stage>
            </Suspense>
            <OrbitControls
              enablePan={false}
              enableZoom={false}
              enableRotate
              autoRotate
            />
          </Canvas>
        </Box>
      </Box>
    </>
  );
};

export default Intro;
