/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function TrophyGold(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('./skribbl_trophy.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle.geometry}
        material={nodes.Circle.material}
        position={[0, 3.04, 0]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle002.geometry}
        material={nodes.Circle002.material}
        position={[0, 0.1, 0]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[2.02, 2.02, 2.02]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane.geometry}
        material={nodes.Plane.material}
        position={[0, 4, 0.72]}
        rotation={[0.64, 0, -Math.PI / 2]}
        scale={[0.39, 0.57, 0.57]}
      />
      <group position={[0.1, 3.18, 0]} rotation={[0, 0, -Math.PI / 2]} scale={[2.68, 2.68, 2.68]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle005.geometry}
          material={nodes.Circle005.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle005_1.geometry}
          material={materials['Material.003']}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder.geometry}
        material={nodes.Cylinder.material}
        position={[0, 0.04, 1.56]}
        rotation={[0.46, 0, 0]}
        scale={[0.15, 3.58, 0.15]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001.geometry}
        material={nodes.Cube001.material}
        position={[0, 3.26, 3.18]}
        rotation={[0.46, 0, 0]}
        scale={[0.17, 0.17, 0.17]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder001.geometry}
        material={nodes.Cylinder001.material}
        position={[0, 0.04, -1.56]}
        rotation={[2.68, 0, Math.PI]}
        scale={[0.15, 3.58, 0.15]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube002.geometry}
        material={nodes.Cube002.material}
        position={[0, 3.26, -3.18]}
        rotation={[2.68, 0, Math.PI]}
        scale={[0.17, 0.17, 0.17]}
      />
      <group position={[0, -3.59, 0]} scale={[1.19, 0.3, 2.05]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_1.geometry}
          material={nodes.Cube001_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_2.geometry}
          material={materials['Material.004']}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/skribbl_trophy.gltf')
