import { Heading, Box } from "@chakra-ui/react";
import * as React from "react";

const Trophies = () => {
  return (
    <Box as="section">
      <Box>
        <Box>
          <Heading className="gradientText" as="h2" size="3xl" maxWidth="700px">
            Schreibe deinen Namen in die Geschichte!
          </Heading>
        </Box>
      </Box>
    </Box>
  );
};

export default Trophies;
