import {
  Heading,
  Box,
  AspectRatio,
  Image,
  Stack,
  Flex,
} from "@chakra-ui/react";
import { useRef, useState, useEffect } from "react";
import "../styles/five.css";
import * as React from "react";

const MiniGame = () => {
  const possibleGuesses = [
    "champignon",
    "campingong",
    "champjingion",
    "champion",
    "champingion",
    "gewinner",
    "champ",
    "podest",
    "waschmaschine",
    "???",
    "indianerzelt",
    "champinon",
    "campignon",
    "schampingion",
    "schampinion",
    "champingion",
    "olympia",
    "hymne",
  ];

  const possibleNames = [
    "Yann",
    "Max",
    "Julia 🧁",
    "Du",
    "Phil",
    "Domi",
    "Woke",
    "Backdoorman",
    ":)",
    "Veronika",
    "Niko",
    "Chantal",
    "Kevin",
    "X Æ A-XII",
    "Jeffrey",
    "Karl",
    "El gringo loco",
    "xXx_SkribblKing_69",
  ];

  const nameColors = [
    "#0000e1",
    "#f30000",
    "#1973fa",
    "#00aa00",
    "#001b9a",
    "#28ce5c",
    "#ffa6ff",
    "#00af8a",
    "#b50000",
    "#00ccc4",
    "#be0026",
    "#97f6fe",
    "#c03800",
    "#004592",
    "#b87900",
    "#c9c9e6",
    "#005331",
    "#382529",
  ];

  const [items, setItems] = useState(["Waschmaschine"]);
  const [names, setNames] = useState(["Domi"]);
  const [timeoutCounter, setTimeoutCounter] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeoutCounter((timeoutCounter) => timeoutCounter + 1);
      if (items.length > 16) {
        items.splice(0, 1);
        names.splice(0, 1);
      }
      let n = names;
      let it = items;
      names.push(
        possibleNames[Math.round(Math.random() * (possibleNames.length - 1))]
      );
      it.push(
        possibleGuesses[
          Math.round(Math.random() * (possibleGuesses.length - 1))
        ]
      );
      setItems(it);
      setNames(n);
    }, 750);
    return () => clearTimeout(timer);
  }, [timeoutCounter]);

  const renderedItems = items.map((item, i) => {
    return (
      <Box
        fontSize="1.1em"
        h="32px"
        p="4px"
        m="8px 0"
        key={item + i.toString()}
      >
        <span style={{ color: nameColors[possibleNames.indexOf(names[i])] }}>
          {names[i]}:
        </span>{" "}
        {item}
      </Box>
    );
  });

  return (
    <Box as="section" mb="120px">
      <Heading textAlign="center" size="md" mb="4">
        Bist du der nächste...
      </Heading>
      <AspectRatio className="minigameMain" ratio={16 / 9} w="100%" m="auto">
        <Box className="minigame">
          <Box w="70%" h="100%" p="16px" bg="rgba(255, 255, 255, 0.2)">
            <Image src="champion.png" />
          </Box>
          <Flex
            bg="gray.100"
            p="16px"
            w="30%"
            color="black"
            h="100%"
            marginTop="auto"
          >
            <Stack>{renderedItems}</Stack>
          </Flex>
        </Box>
      </AspectRatio>
    </Box>
  );
};

export default MiniGame;
